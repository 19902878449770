import React, { Component } from "react";
import { ScheduleMeeting, timeSlotDifference } from "react-schedule-meeting"


// Helper function to parse string time to int
function parseTime(timeToConvert) {
    let splitString = timeToConvert.split(":");
    let hours = parseInt(splitString[0]);
    let minutes = parseInt(splitString[1]);
    let seconds = parseInt(splitString[2]);

    let parsedTime = [hours, minutes, seconds];

    return parsedTime;
}


//Adds all of the days x amount away from start date to an array
function getDates(startDate, daysToAdd) {
    let dates = [];

    for (let i = 0; i <= daysToAdd; i++) {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0);
        currentDate.setDate(startDate.getDate() + i);
        dates.push(currentDate);
    }

    return dates;
}


//Checks whether there are any bookings for a given day
function checkBookings(bookings, datesToCheck) {
    let relevantBookings = [];
    datesToCheck.forEach(dateToCheck => {
        bookings.forEach(booking => {
            let dateToCompare = new Date(booking.bookstart)
            if (dateToCompare.setHours(0, 0, 0, 0) === dateToCheck.setHours(0, 0, 0, 0)) {
                relevantBookings.push(booking)
            }
        });
    });
    return relevantBookings;
}


//Calculates all of the availiable times given the bookings from the db
function timesAvailable(bookings, zoneTimes) {
    try {
        let today = new Date();
        let calendarDates = getDates(today, 365);
        let calendarBookings = [];

        //Populates calendarBookings with all bookings for the dates provided.
        calendarBookings = checkBookings(bookings, calendarDates);

        //Gets all of the bookings for the period then converts them to time periods on the relevant date
        let unavailableTimeSlots = [];
        calendarBookings.forEach(booking => {
            unavailableTimeSlots.push({
                startTime: new Date(booking.bookstart),
                endTime: new Date(booking.bookend)
            });
        });

        //Convert the given zone's open and close times to a format that can be used below
        let zoneOpen = parseTime(zoneTimes.zoneopen);
        let zoneClose = parseTime(zoneTimes.zoneclose);


        //Gets all of the dates and sets the availiable times as the zone open and close times
        let availableTimeSlots = [];
        calendarDates.forEach(day => {
            availableTimeSlots.push({
                startTime: new Date(day.setHours(zoneOpen[0], zoneOpen[1], zoneOpen[2])),
                endTime: new Date(day.setHours(zoneClose[0], zoneClose[1], zoneClose[2]))
            });
        });

        let calculatedAvailableTimeSlots = []

        //Try catch is used here to avoid issue where the current day may have no available time which results in the helper function throwing an error
        //Helper function from react calendar component is used below which subtracts all the booking times and returns the times that are currently free to be booked

        try {
            calculatedAvailableTimeSlots = timeSlotDifference(availableTimeSlots, unavailableTimeSlots);
        }
        catch (err) {
            availableTimeSlots.shift()
            calculatedAvailableTimeSlots = timeSlotDifference(availableTimeSlots, unavailableTimeSlots);
        }
        return calculatedAvailableTimeSlots;
    }
    catch (err) {
        console.log("error")
        console.log(err)
    }
}


//Simple react class component that renders the calender component populated with the output of above.
export default class Calendar extends Component {

    handleTimeslotClicked = (startTimeEventEmit) => {
        startTimeEventEmit.resetSelectedTimeState();
    };

    render() {
        return (
            <ScheduleMeeting
                borderRadius={10}
                primaryColor="#004f70"
                eventDurationInMinutes={30}
                eventStartTimeSpreadInMinutes={30}
                availableTimeslots={timesAvailable(this.props.zoneBookings, this.props.zoneTimes)}
                startTimeListStyle="scroll-list"
                skipConfirmCheck="YES"
                onStartTimeSelect={this.handleTimeslotClicked}
            />
        )
    }
}