import React, { Component } from "react";
import Calendar from "./Calendar";
import axios from 'axios';
import '../App.css'
import logo from '../icc-logo.jpg'
import { Audio } from 'react-loader-spinner'
import moment from "moment"


//Helper function that grabs the zoneid from the url
function paramFetcher() {
    const searchParams = new URLSearchParams(document.location.search)
    return searchParams.get('zone')
}


export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoneId: "",
            zoneBookings: null,
            zoneTimes: {},
            zoneDetails: [],
            errorState: false,
            isLoading: true,
        }
    }

    getDbInfo() {
        let data = this.state.zoneId;

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://ir0t6k4wy9.execute-api.ap-southeast-2.amazonaws.com/prod/park-booking',
            headers: {
                'Content-Type': 'text/plain'
            },
            data: data
        };
        //Make call to api gateway then grab the information
        axios.request(config)
            .then((response) => {
                let zoneDetailsResponse = response.data;
                zoneDetailsResponse = zoneDetailsResponse.zone_details;

                let zoneBookingsResponse = response.data;
                zoneBookingsResponse = zoneBookingsResponse.bookings;

                let zoneTimesResponse = response.data;
                zoneTimesResponse = zoneTimesResponse.zone_times[0]

                //Validate all of the above information then set respective states
                if (zoneDetailsResponse.length !== 0 && zoneBookingsResponse.length !== 0 && zoneTimesResponse.length !== 0) {
                    this.setState({ zoneDetails: zoneDetailsResponse })
                    this.setState({ zoneBookings: zoneBookingsResponse })
                    this.setState({ zoneTimes: { zoneopen: zoneTimesResponse.zoneopen, zoneclose: zoneTimesResponse.zoneclose } })
                    this.setState({ isLoading: false })
                }
                //If there is an issue with db responses stop loading and set error
                else {
                    this.setState({ isLoading: false })
                    this.setState({ errorState: true })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    //componentDidMount is used by the app to set all of the needed states then trigger a re render.
    componentDidMount() {
        //This fetches the zoneid from url bar
        let urlZoneId = paramFetcher()
        if (urlZoneId) {
            // Set zoneID then using callback hook for the setstate make an axios call to apigateway to retrieve all needed info
            this.setState({ zoneId: urlZoneId }, () => {
                this.getDbInfo();
            })
        }
        else {
            //If no zoneId set error state and stop loading
            this.setState({ isLoading: false })
            this.setState({ errorState: true })
        }
    }

    render() {
        return (
            <>
                <div className="colour-line"></div>
                <div className="ipswich-logo-container">
                    <img className="ipswich-logo" src={logo} alt="ipswich-logo" />
                </div>

                {this.state.isLoading ? (
                    <div className="ipswich-loader">
                        <Audio color="#005339" ariaLabel="loading" />
                    </div>
                ) : (
                    null
                )}

                {this.state.errorState ? (
                    <div className="ipswich-headers">
                        <h2>Oops. No zone was selected, please return to <a href='https://www.ipswich.qld.gov.au/explore/parks_reserves_precincts/parks_search'>Parks Search</a> and select a zone</h2>
                    </div>
                ) : (
                    this.state.zoneDetails.map(detail => {
                        return (
                            <div key={detail} className="ipswich-headers">
                                <h2 key={detail.parkname}> {detail.parkname} Booking Availability</h2>
                                <h3 key={detail.zonename}> Zone: {detail.zonename} </h3>
                            </div>
                        )
                    })
                )}

                {this.state.zoneBookings ? (
                    <div className="ipswich-body">
                        <div className="booking-notes">
                            <p>
                                <br />To check the availability of this area, choose a date from the calendar below. The times indicated are a guide only. If the area is available, please complete and submit an application.
                                <br /><strong>(Area availability does not guarantee approval.)</strong>
                                <br />
                                <br /> The selected zone is open {moment(this.state.zoneTimes.zoneopen, 'h:mm a').format('hh:mm A')} - {moment(this.state.zoneTimes.zoneclose, 'h:mm a').format('hh:mm A')}
                            </p>
                        </div>
                        <Calendar zoneBookings={this.state.zoneBookings} zoneTimes={this.state.zoneTimes} />
                    </div >
                ) : (
                    null
                )}
            </>
        )
    }
}