import React from 'react';
import App from "./Components/App"
import ReactDOM from 'react-dom/client'
import { Routes, Route, BrowserRouter } from "react-router-dom"

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(
  <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />}></Route>
        </Routes>
    </BrowserRouter>
  );